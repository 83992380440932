import {Component, OnInit, AfterViewInit,Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { NgxSmartModalService } from 'ngx-smart-modal';


@Component({
  selector: 'stepper',
  templateUrl: 'stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class BasicStepper implements OnInit {

    @Input() showone: boolean;
    @Input() showtwo: boolean;
    @Input() showthree: boolean;

  constructor(private _formBuilder: FormBuilder, public ngxSmartModalService: NgxSmartModalService) {}

  ngOnInit() {


  } 
 
}