import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location, DatePipe} from '@angular/common';
import { DriverEnroll } from './../../models/driver-enroll';
import { DriverRequest } from './../../models/driverrequest';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverEnrollService } from './../../services/driver-enroll/driver-enroll.service';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import { AlertService } from './../../services/alert/alert.service';


@Component({
    selector: 'app-upload-csv',
    templateUrl: './upload-csv.component.html',
    styleUrls: ['./upload-csv.component.css']
})
export class UploadCsvComponent implements OnInit {

    driverEnroll = new DriverEnroll();
    fileTypeNotSupportedErrorMessage: string;
    uploadedFileName = 'No File Selected';
    uploadedFile = null;

    constructor(private driverEnrollService: DriverEnrollService, private router: Router,
        private alertService: AlertService,
        private spinner: NgxSpinnerService, private authService: AuthenticationService, private _location: Location, private datePipe: DatePipe) { }

    ngOnInit() {
    }

    cancelUpload() {
        this._location.back();
    }

    fileUpload(fileObj) {
        if (fileObj && fileObj.target.files.length > 0) {
            const file = fileObj.target.files[0];
            const isFileValid = this.fileValidator(file.name);
            if (!isFileValid) {
                this.fileTypeNotSupportedErrorMessage = 'File type not supported';
            } else {
                this.fileTypeNotSupportedErrorMessage = '';
                this.uploadedFile = file;
                this.uploadedFileName = file.name;
            }
        }
    }
    getHeaderArray(csvRecordsArr: any) {
        let headers = csvRecordsArr[0].split(',');
        let headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }
    getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
      var dataArr = []
      let user = JSON.parse(this.authService.getLocalStorageItem("loginUser"));

        for (let i = 1; i < csvRecordsArray.length; i++) {
            let data = csvRecordsArray[i].split(',');
            if (data.length == headerLength) {
                var csvRecord: DriverEnroll = new DriverEnroll();
                //EmployeeNumber, UnitNumber, First Name, Last Name, Address 1 (As on Driver License), Address 2 (if needed),City, State, ZIP, Date of Birth(MM - DD - YYYY), Type(Employee or Contractor), Full SSN(No Dashes), Full EIN(No Dashes), Phone Number(No Dashes), Email Address
                csvRecord.customerId = user.customerId;
                csvRecord.employeeNumber = data[0];
                csvRecord.unitNumber = data[1];
                csvRecord.firstName = data[2];
                csvRecord.lastName = data[3];
                csvRecord.address1 = data[4];
                csvRecord.address2 = data[5];
                csvRecord.city = data[6];
                csvRecord.state = data[7];
                csvRecord.zipCode = data[8];
                csvRecord.dateOfBirth = this.datePipe.transform(data[9], "yyyy-MM-dd");
                //if (data[10]) {
                csvRecord.typeValue = data[10];
                //}
                //else if (data[11]) {
                //  csvRecord.typeValue = data[11];
                //}

                //csvRecord.ssn = data[10];
                //csvRecord.ein = data[11];
                csvRecord.phone = data[11];
                csvRecord.email = data[12];
                dataArr.push(csvRecord);
            }
        }
        return dataArr;
    }
    private fileValidator(fileName) {
        let fileType = [];
        const allowedFileTypes = ['csv'];
        fileType = fileName.split('.');
        return allowedFileTypes.includes(fileType[(fileType.length - 1)]);
    }
    submitImportCSVFile() {

        if (this.uploadedFileName !== 'No File Selected' && this.uploadedFileName.length > 0) {
            this.spinner.show();
            var text = [];
            var reader = new FileReader();
            reader.readAsText(this.uploadedFile)

            reader.onload = (data) => {
                let csvData = reader.result;
                let csvRecordsArray = csvData.split(/\r\n|\n/);
                let headersRow = this.getHeaderArray(csvRecordsArray);

                let cdata = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
                console.log(cdata);
                let user = JSON.parse(this.authService.getLocalStorageItem("loginUser"));
                let driverRequest: DriverRequest = new DriverRequest();
                driverRequest.accountCode = user.accountCode;
                driverRequest.drivers = cdata;
                this.driverEnrollService.submitDriverEnrolls(driverRequest)
                    .subscribe(data => {
                        if (data) {
                            this.spinner.hide();
                            this.alertService.success('Submit Drivers Enroll Successfully');
                            this.router.navigateByUrl('/home');
                            this.alertService.success(cdata.length +' Record have been uploaded Successfully');
                        }
                    },
                    error => {
                        this.spinner.hide();
                        this.alertService.error(error);
                    });
            }

            reader.onerror = function () {
                alert('Unable to read');
            };
        } else {
            this.fileTypeNotSupportedErrorMessage = '';
        }
        this.spinner.hide();
    }

}
