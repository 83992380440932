import { DriverEnroll } from './driver-enroll';


export class DriverRequest {
    public accountCode: string;
    public drivers: DriverEnroll[]

   

    
}
