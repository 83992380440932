import { Address } from './address';

export class DriverEnroll {
    public customerId: string;
    public driverId: number;
    public driverStatus: string;
    public employeeNumber: string;
    public unitNumber: string;
    public firstName: string;
    public lastName: string;
    public address1: string;
    public address2: string;
    public phone: string;
    public email: string;
    public type: string;
    public ssn: string;
    public ein: string;
    public typeValue: string;
    public dateOfBirth: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public driverSubmitted: string;
    public selected: boolean;
    public responseCode: string;
    public responseMessage: string;

    constructor() { }
}
