import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from './../../services/alert/alert.service';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ForgotPassword } from './../../models/forgotpassword';
import { first } from 'rxjs/operators';
import { CustomValidators } from '../../custom-validators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    forgotPWDForm: FormGroup;
    submitted = false;
    forgotPassword: ForgotPassword = new ForgotPassword();
    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService) {
        this.forgotPWDForm = this.createForgotPWDForm();
    }

  ngOnInit() {
    }

  createForgotPWDForm(): FormGroup {
      return this.fb.group(
          {
              username: ['', Validators.required],
              customerid: ['', Validators.required],
              email: ['', [Validators.required, CustomValidators.patternValidator(/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,3}$/, {
                  valid: true
              })]],
          }
      );
  }

  get f() { return this.forgotPWDForm.controls; }

  submit() {
     
      this.submitted = true;
      if (this.forgotPWDForm.invalid) {
          return;
      }
      this.spinner.show();
      this.forgotPassword.username = this.forgotPWDForm.value.username;
      this.forgotPassword.customerid = this.forgotPWDForm.value.customerid;
      this.forgotPassword.email = this.forgotPWDForm.value.email;

      this.authenticationService.forgotpassword(this.forgotPassword)
          .pipe(first())
          .subscribe(
          user => {
             
              this.spinner.hide();
              this.router.navigateByUrl('/login');
              this.alertService.success('Your temporary password has been sent to the email provided.');
          },
          error => {
              this.spinner.hide();
              this.alertService.error('Invalid CustomerId or Email');
              //this.alertService.success('Invalid CustomerId or Email');
          });

  }

}
