import { AlertService } from './../../services/alert/alert.service';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangePassword } from './../../models/changepassword';
import { first } from 'rxjs/operators';
import { CustomValidators } from '../../custom-validators';



@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent  {

    resetForm: FormGroup;
    showPasswordFlag = false;
    showConfPasswordFlag = false;
    submitted = false;
    loading = false;
    changePassword: ChangePassword = new ChangePassword();
    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService) {
        this.resetForm = this.createSignupForm();
    }

    createSignupForm(): FormGroup {
        return this.fb.group(
            {
               
                password: [
                    null,
                    Validators.compose([
                        Validators.required,
                        // check whether the entered password has a number
                        CustomValidators.patternValidator(/\d/, {
                            hasNumber: true
                        }),
                        // check whether the entered password has upper case letter
                        CustomValidators.patternValidator(/[A-Z]/, {
                            hasCapitalCase: true
                        }),
                        // check whether the entered password has a lower case letter
                        CustomValidators.patternValidator(/[a-z]/, {
                            hasSmallCase: true
                        }),
                        // check whether the entered password has a special character
                        CustomValidators.patternValidator(
                            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
                            {
                                hasSpecialCharacters: true
                            }
                        ),
                        Validators.minLength(8)
                    ])
                ],
                confirmPassword: ['',Validators.compose([Validators.required])]
            },
            {
                // check whether our password and confirm password match
                validator: CustomValidators.passwordMatchValidator
            }
        );
    }

    get f() { return this.resetForm.controls; }
    submit() {
        this.submitted = true;
        if (this.resetForm.invalid) {
            return;
        }
        this.loading = true;
        let user = JSON.parse(this.authenticationService.getLocalStorageItem("loginUser"));
        this.changePassword.username = user.username;
        this.changePassword.newpassword = this.resetForm.value.password;
        if (user.currentState == "FirstTimeUser")
            this.changePassword.isfirsttimeuser = true;
        else
            this.changePassword.isfirsttimeuser = false;
        this.authenticationService.changepassword(this.changePassword)
            .pipe(first())
            .subscribe(
            response => {
                this.spinner.hide();
                switch (user.currentState) {
                    case 'FirstTimeUser':
                        this.router.navigateByUrl('/landing');
                        break;
                    case 'LandingPage':
                        this.router.navigateByUrl('/landing');

                        break;
                    case 'Addendum':
                        this.router.navigateByUrl('/addendum');
                        break;
                    case 'Add-driver':
                        this.router.navigateByUrl('/driver-home');
                        break;
                    case 'Driver-list':
                        this.router.navigateByUrl('/home');
                        break;
                    case 'Completed':
                        this.router.navigateByUrl('/home');
                        break;
                    default: this.router.navigateByUrl('/verify');

                }
            },
            error => {
                this.spinner.hide();
                this.alertService.error(error);
                this.loading = false;
            });
        
    }
}
