import { UserService } from './../../services/user/user.service';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import { User } from './../../models/user';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from './../../services/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    userLogin: User;
    showPasswordFlag = false;

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService) {
    }
    ngOnInit() {
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        
        this.authenticationService.logout();
        
    }

    forgotPassword() {
        this.router.navigateByUrl('/forgot-password');
      }

    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            this.alertService.error("Invalid username or password. Please verify information and try again or contact Customer Service for assistance");
        }
        this.loading = true;
        this.userLogin = Object.assign({}, this.loginForm.value);
        this.spinner.show();
        this.authenticationService.login(this.userLogin)
            .pipe(first())
            .subscribe(
            user => {
                this.spinner.hide();
                //console.log("User object returned" + JSON.stringify(user));
                user.customerId = user.customerIds[0];
                this.authenticationService.setLocalStorageItem(user);
                if (user.isTempPwd == "true") {
                    this.router.navigateByUrl('/changePassword');
                }
                else {
                    switch (user.currentState) {
                        case 'FirstTimeUser':
                            this.router.navigateByUrl('/verify');
                            break;
                        case 'LandingPage':
                            this.router.navigateByUrl('/landing');

                            break;
                        case 'Addendum':
                            this.router.navigateByUrl('/addendum');
                            break;
                        case 'Add-driver':
                            this.router.navigateByUrl('/driver-home');
                            break;
                        case 'Driver-list':
                            this.router.navigateByUrl('/home');
                            break;
                          case 'Submitted':
                            this.router.navigateByUrl('/home');
                            break;
                        case 'Completed':
                            this.router.navigateByUrl('/home');
                            break;
                        default: this.router.navigateByUrl('/verify');

                    }
                }

            },
            error => {
                this.spinner.hide();
                this.alertService.error("Invalid username or password. Please verify information and try again or contact Customer Service for assistance");
                this.loading = false;
            });
    }

}
