import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StatusUpdate } from './../../models/statusrequest';
import { first } from 'rxjs/operators';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import { AlertService } from './../../services/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverEnrollService } from './../../services/driver-enroll/driver-enroll.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas'; 

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

    statusUpdate: StatusUpdate = new StatusUpdate();
    constructor(private driverEnrollService: DriverEnrollService, private router: Router, private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  letsGetStarted() {
      let user = JSON.parse(this.authenticationService.getLocalStorageItem("loginUser"));
      this.statusUpdate.accountCode = user.accountCode;
      this.statusUpdate.currentstatus = "addendum";
      this.authenticationService.updateStatus(this.statusUpdate)
          .pipe(first())
          .subscribe(
          user => {
              this.spinner.hide();
              this.router.navigateByUrl('/addendum');
          },
          error => {
              this.spinner.hide();
              this.alertService.error(error);

          });
   
  }
  

}
