import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-driver-home',
  templateUrl: './driver-home.component.html',
  styleUrls: ['./driver-home.component.css']
})
export class DriverHomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  letsCountinue() {
    this.router.navigateByUrl('/add-driver');
  }

  uploadCSV() {
    this.router.navigateByUrl('/upload-csv');
  }

}
