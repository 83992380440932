import { Address } from './address';

export class CustomerEnroll {
    public customerId: string;
    
    public brand: string;

    public email: string;
    public password: string;
    public tc: boolean;
    public username: string;

    constructor() { }
}
