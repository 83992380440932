import { environment } from './../../../environments/environment';
import { DriverEnroll } from './../../models/driver-enroll';
import { DriverRequest } from './../../models/driverrequest';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { AuthenticationService } from './../../services/authentication/authentication.service';



@Injectable({
  providedIn: 'root'
})
export class DriverEnrollService {
    
   baseUrl = environment.baseUrl;

  constructor(private http: HttpClient,private authService :AuthenticationService) {}

  
  getAllDriverEnrolls(accountCode:string): Observable<DriverEnroll[]> {
      let url = this.baseUrl + '/drivers/' + accountCode;
      return this.http.get<DriverEnroll[]>(url)
          .pipe(map(data => {
              return data;
          }));
    
  }

  downloadPDFFile(accountCode:string) {
      let url = this.baseUrl + '/addendum/' + accountCode;
      const httpOptions = {
          'responseType': 'arraybuffer' as 'json'
          //'responseType'  : 'blob' as 'json'        //This also worked
      };

      return this.http.get<any>(url, httpOptions);

  }

  signPDFFile(accountCode: string, signedBy: string) {
      let url = this.baseUrl + '/addendum/' + accountCode + '?signedBy=' + signedBy;
      const httpOptions = {
          'responseType': 'arraybuffer' as 'json'
      };
     
      return this.http.post<any>(url, httpOptions);
      
  }



  createPDFFile(accountCode:string) {
      let url = this.baseUrl + '/addendum/create/' + accountCode;
     
  const httpOptions = {
         'responseType': 'arraybuffer' as 'json'
     };
  return this.http.get<any>(url, httpOptions);
 }
  submitDriverEnrolls(driverEnrolls: DriverRequest) {
      let url = this.baseUrl + '/drivers';
      return this.http.post(url, driverEnrolls);
  }
  updateDriverEnrolls(driverEnrolls: DriverRequest) {
      let url = this.baseUrl + '/updatedriver';
      return this.http.post(url, driverEnrolls);
  }
  deleteDriverEnrolls(driverEnrolls: DriverRequest) {
      let url = this.baseUrl + '/deletedriver';
      return this.http.post(url, driverEnrolls);
  }
  updateDriversEnrolls(driverEnrolls: DriverRequest) {
      let url = this.baseUrl + '/updatedrivers';
      return this.http.post(url, driverEnrolls);
  }

}
