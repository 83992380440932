import { CustomerEnroll } from './../../models/customer-enroll';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { AuthenticationService } from './../../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerEnrollService {

  // below endpoint URL with Web API localhost:8080 in Dev or Prod environments
  // private endpointURL_All = "driverEnroll/all";

  // pleae comment this later when mock testing is done
  // private endpointURL_All = "mockdata/driver-enroll/all-driverEnrolls.json";
    //private endpointURL_All = "https://onroad-icd.azurewebsites.net/onRoadAPI/api/drivers";

    
    private endpointURL_All = "http://localhost:57948/api/accounts";

  constructor(private http: HttpClient,private authService :AuthenticationService) {}

  /** GET DriverEnroll from the server */
 
  submitCustomerEnrolls(customerEnrolls: CustomerEnroll[]) {
      return this.http.post(this.endpointURL_All, customerEnrolls);
  }
 

}
