import { AlertService } from '../../services/alert/alert.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangePassword } from '../../models/changepassword';
import { first } from 'rxjs/operators';
import { CustomValidators } from '../../custom-validators';
import { stringify } from '@angular/core/src/util';
import { LowerCasePipe } from '@angular/common';


@Component({
    selector: 'app-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {

    public frmSignup: FormGroup;
    showPasswordFlag = false;
    showConfPasswordFlag = false;
    submitted = false;
    verifyform: any = {};
    customerIDfromDB: string;
    customerIDfromUser: string;


    public barLabel: string = "Password strength:";
    constructor(private fb: FormBuilder, private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService) {
    }
    ngOnInit() {
        this.verifyform = this.fb.group({
            customerid: ['', Validators.required],
        });
    }
    get f() { return this.verifyform.controls; }

    submit() {
        this.submitted = true;
        if (this.verifyform.invalid) {
            return;
        }
        let user = JSON.parse(this.authenticationService.getLocalStorageItem("loginUser"));
        this.customerIDfromDB = user.customerId;
        this.customerIDfromUser = this.verifyform.value.customerid;
        if (this.customerIDfromDB.indexOf(this.customerIDfromUser.toUpperCase()) > -1) {
            this.router.navigateByUrl('/changePassword');
        }
        else {
            this.alertService.error("Invalid Customer ID. Please verify information and try again or contact Customer Service for assistance.  You may locate your Customer ID on your last invoice or by viewing in iconnectdata.com");
        }
    }
}
