import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppInsightsService } from './services/app-insights/app-insights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'OnRoadUI';

  constructor(private appInsightsService: AppInsightsService, private router: Router) {
    appInsightsService.logPageView('landing-page');
  }
}
