import { AuthenticationService } from './../../services/authentication/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverEnrollService } from './../../services/driver-enroll/driver-enroll.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    constructor(public authenticationService: AuthenticationService, private router: Router, private spinner: NgxSpinnerService, private driverEnrollService: DriverEnrollService) { }
    ngOnInit() {
    }

    getPDF() {
        this.spinner.show();
        let user = JSON.parse(this.authenticationService.getLocalStorageItem("loginUser"));
        this.driverEnrollService.downloadPDFFile(user.accountCode)
            .subscribe(data => {
                let file = new Blob([data], { type: 'application/pdf' });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(file);
                    return;
                }

                // For other browsers: 
                // Create a link pointing to the ObjectURL containing the blob.
                const fdata = window.URL.createObjectURL(file);

                var link = document.createElement('a');
                link.href = fdata;
                link.download = "Addendum_" + user.accountCode;
                // this is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

                setTimeout(function () {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
                this.spinner.hide();

            }, (error) => {
                this.spinner.hide();
               // this.alertService.error(error);
            });
    }

  logout(){
    this.router.navigateByUrl('/login');
  }

}
