import { AlertService } from './../../services/alert/alert.service';
import { CustomerEnrollService } from './../../services/customer-enroll/customer-enroll.service';
import { PagerService } from './../../services/pagination/pager.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerEnroll } from './../../models/customer-enroll';
import { DriverRequest } from './../../models/driverrequest';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../services/authentication/authentication.service';


@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

 
 
  fileTypeNotSupportedErrorMessage: string;
  uploadedFileName = 'No File Selected';
  uploadedFile = null;

  // sorting asc/desc initialization 
 
  constructor(private customerService: CustomerEnrollService, private router: Router,
  private alertService: AlertService,
    private spinner: NgxSpinnerService, private authService: AuthenticationService) { }

  ngOnInit() {
     
  }


  fileUpload(fileObj) {
    if (fileObj && fileObj.target.files.length > 0) {
      const file = fileObj.target.files[0];
      const isFileValid = this.fileValidator(file.name);
      if (!isFileValid) {
        this.fileTypeNotSupportedErrorMessage = 'File type not supported';
      } else {
        this.fileTypeNotSupportedErrorMessage = '';
        this.uploadedFile = file;
        this.uploadedFileName = file.name;
      }
    }
  }
  getHeaderArray(csvRecordsArr: any) {
      let headers = csvRecordsArr[0].split(',');
      let headerArray = [];
      for (let j = 0; j < headers.length; j++) {
          headerArray.push(headers[j]);
      }
      return headerArray;
  }
  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
      let customerenroll: CustomerEnroll[] = [];

      for (let i = 1; i < csvRecordsArray.length; i++) {
          let data = csvRecordsArray[i].split(',');
          // FOR EACH ROW IN CSV FILE IF THE NUMBER OF COLUMNS
          // ARE SAME AS NUMBER OF HEADER COLUMNS THEN PARSE THE DATA
          if (data.length == headerLength) {

              var csvRecord: CustomerEnroll = new CustomerEnroll();

              csvRecord.customerId = data[0].trim();
              csvRecord.brand = data[1].trim();
              csvRecord.email = data[2].trim();
              csvRecord.tc = data[3].trim();
              csvRecord.username = data[4].trim();
              customerenroll.push(csvRecord);
             
          }
      }
      return customerenroll;
  }
  private fileValidator(fileName) {
    let fileType = [];
    const allowedFileTypes = ['csv', 'xls', 'xlsx'];
    fileType = fileName.split('.');
    return allowedFileTypes.includes(fileType[(fileType.length - 1)]);
  }

  // uncomment this when API is ready to send CSV file to API
  submitImportCSVFile() {
      debugger;
     if (this.uploadedFileName !== 'No File Selected' && this.uploadedFileName.length > 0) {

      // set the spinner to show and alter hide below
       this.spinner.show();
           var reader = new FileReader();
           reader.readAsText(this.uploadedFile)
           reader.onload = (data) => {
               let csvData = reader.result;
               let csvRecordsArray = csvData.split(/\r\n|\n/);
               let headersRow = this.getHeaderArray(csvRecordsArray);

               let cdata = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
               let users = JSON.parse(this.authService.getLocalStorageItem("loginUser"));
              
              // driverRequest.drivers.push(this.driverEnroll);

               this.customerService.submitCustomerEnrolls(cdata)
                   .subscribe(data => {
                       if (data) {
                           this.spinner.hide();
                           // assuming API returns updated data list of all Drivers who Enrolled to show in dashboard
                           
                           this.alertService.success('Submit customer Enroll Successfully');
                         
                       }
                   },
                   error => {
                       this.spinner.hide();
                       this.alertService.error(error);
                   });
               
           }

           reader.onerror = function () {
               alert('Unable to read');
           };
      
     

       //const formData = new FormData();
       //formData.append('csvFile', this.uploadedFile, this.uploadedFile.name);
       //this.driverEnrollService.submitImportCSVFile(formData)
       //  .subscribe(data => {
       //    this.spinner.hide();  
       //    if (data) {
       //      this.pagerService.allItems = data.map(item => Object.assign({}, item));
       //      this.fileTypeNotSupportedErrorMessage = '';
       //    }
       //  },
       //    error => {
       //      this.spinner.hide();  
       //      this.fileTypeNotSupportedErrorMessage = error.error.message;
       //    });
     } else {
       this.fileTypeNotSupportedErrorMessage = '';
      }
     
   }

}
