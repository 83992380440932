import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

/** A hero's name can't match the given regular expression */
export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const forbidden = nameRe.test(control.value);
        return forbidden ? { 'forbiddenName': { value: control.value } } : null;
    };
}

@Directive({
    selector: '[appForbiddenName]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ForbiddenValidatorDirective, multi: true }]
})
export class ForbiddenValidatorDirective implements Validator {
    @Input('appForbiddenName') forbiddenName: string;
    
    

    validate(control: AbstractControl): { [key: string]: any } {
       
        const arr = this.forbiddenName.split(',');
        var valid;
        for (var i = 0; i < arr.length; i++) {

            if (forbiddenNameValidator(new RegExp(arr[i], 'i'))(control)) {
                valid = forbiddenNameValidator(new RegExp(arr[i], 'i'))(control);
            }
        }
        if (valid) {
            return valid;
        }
        else
            return null;
        
    }
}



