import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PagerService {

  // pager object
  private _pager: any = {};

  // paged items
  private _pagedItems: any[];

  // array of all items to be paged
  private _allItems: any[];

  private _lazyLoad: boolean = false;

  constructor() {
    this._allItems = [];
    this._pagedItems = [];
  }

  public get pager(): any {
    return this._pager;
  }

  public set pager(pagerParam: any) {
    this._pager = pagerParam;
  }

  public get pagedItems(): any {
    return this._pagedItems;
  }

  public set pagedItems(pagedItemsParam: any) {
    this._pagedItems = pagedItemsParam;
  }

  public get allItems(): any {
    return this._allItems;
  }

  public set allItems(allItemsParam: any) {
    this._allItems = allItemsParam;
  }

  public get lazyLoad(): boolean {
    return this._lazyLoad;
  }

  public set lazyLoad(lazyLoadParam: boolean) {
    this._lazyLoad = lazyLoadParam;
  }

  public setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages || page === this.pager.currentPage) {
      return;
    }

    this.pager = this.getPager(this.allItems.length, page);

    if (!this.lazyLoad) {
      this.pagedItems = [];
      this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }

  }

  public getPager(totalItems: number, currentPage: number = 1): any {
    // number of items per page is 10 from business
    let pageSize: number = 20;
    let showTotalPages: number = 5;
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= showTotalPages) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = showTotalPages;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    // let pages = _.range(startPage, endPage + 1);
    let pages = Array.from(Array(endPage + 1 - startPage), (_, i) => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      showTotalPages: showTotalPages,
      pages: pages
    };
  }
}
