import { AlertService } from './../../services/alert/alert.service';
import { DriverEnrollService } from './../../services/driver-enroll/driver-enroll.service';
import { PagerService } from './../../services/pagination/pager.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverEnroll } from './../../models/driver-enroll';
import { DriverRequest } from './../../models/driverrequest';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import { JsonPipe, DatePipe } from '@angular/common';

import { StatusUpdate } from './../../models/statusrequest';
import { AppInsightsService } from '../../services/app-insights/app-insights.service';



@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    public driverEnroll = new DriverEnroll();
    public allDriverEnrolls: DriverEnroll[] = [];
    isHome: boolean = true;
    driverTypeShow: boolean;
    search4DriverEnroll: string = "";
    enableSubmitDriverEnrollsBtnFlag = false;
    showModalFlag: string = "addDriverEnrollModal";
    editDriverEnrollIndex: number;
    fileTypeNotSupportedErrorMessage: string;
    uploadedFileName = 'No File Selected';
    uploadedFile = null;
    records: Array<any>;
    isDesc: boolean = false;
    column: string = 'firstName';
    direction: number;
    showthree: boolean = false;
    showSubmitDriverButton: boolean = false;
    disabledAddDriverButton: boolean = false;
    allRecordsSubmitted: boolean = false;
    public showDocumentDropdown = false;

    constructor(private driverEnrollService: DriverEnrollService, private router: Router,
      public pagerService: PagerService, private alertService: AlertService, private appInsightsService: AppInsightsService,
      private spinner: NgxSpinnerService, private authService: AuthenticationService, private datePipe: DatePipe) {
      appInsightsService.logPageView('add-driver');
    }

    ngOnInit() {
        this.showAll();
        this.authService.showDocumentDropdown = true;
    }

    showAll() {
        this.spinner.show();
        let user = JSON.parse(this.authService.getLocalStorageItem("loginUser"));
        this.driverEnrollService.getAllDriverEnrolls(user.accountCode)
            .subscribe(data => {
                this.spinner.hide();
                this.allDriverEnrolls = data;
                if (this.allDriverEnrolls.length > 0) {
                    //console.log("DriverList Data" + JSON.stringify(data));
                    var bookFilteredList = this.allDriverEnrolls
                        .filter((driver: DriverEnroll) => driver.driverSubmitted == 'N' && driver.driverStatus == '1');

                    if (bookFilteredList.length > 0)
                        this.showSubmitDriverButton = true;
                    else
                        this.showSubmitDriverButton = false;

                    var getSubmittedRecords = this.allDriverEnrolls
                        .filter((driver: DriverEnroll) => driver.driverSubmitted == 'Y' && driver.driverStatus=='1');
                
                    if (getSubmittedRecords.length > 0){
                        console.log("Filtered Data length" + getSubmittedRecords.length);
                        this.disabledAddDriverButton = true;
                        this.alertService.success(getSubmittedRecords.length + ' records have been submitted as of ' + (this.datePipe.transform(Date.now(), "MM-dd-yyyy") + '.  Please allow 3-5 business days for processing & card shipment to your Company address on file.  Please contact Customer Service for any questions'));
                    } 
                    else
                        this.disabledAddDriverButton = false;
                    var checkAllSubmitted = this.allDriverEnrolls
                        .filter((driver: DriverEnroll) => driver.driverSubmitted == 'N');

                    if (checkAllSubmitted.length > 0)
                        this.showthree = false;
                    else {
                        this.showthree = true;
                        this.allRecordsSubmitted = true;
                        this.alertService.success(this.allDriverEnrolls.length + ' records have been submitted as of ' + (this.datePipe.transform(Date.now(), "MM-dd-yyyy") + '.  Please allow 3-5 business days for processing & card shipment to your Company address on file.  Please contact Customer Service for any questions'));
                        this.updatestate(user.accountCode);
                    }
                }
            }, (error) => {
                this.spinner.hide();
                this.alertService.error(error);
            });

    }

    updatestate(accountCode: string) {
        debugger;
        this.spinner.show();
        var statusUpdate: StatusUpdate = new StatusUpdate();
        //let user = JSON.parse(this.authService.getLocalStorageItem("loginUser"));
        statusUpdate.accountCode = accountCode;
        statusUpdate.currentstatus = "completed";
        this.authService.updateStatus(statusUpdate)
            .subscribe(
            user => {
                this.spinner.hide();
            },
            error => {
                this.spinner.hide();
                this.alertService.error(error);

            });

    }
    sortBy(propertyParam) {
        this.isDesc = !this.isDesc; //change the direction    
        this.column = propertyParam;
        this.direction = this.isDesc ? 1 : -1;
    }
    editDriverEnroll(eachDriverEnroll: DriverEnroll, editDriverEnrollIndex) {
        eachDriverEnroll.typeValue = '';
        this.driverEnroll = eachDriverEnroll;
        this.editDriverEnrollIndex = editDriverEnrollIndex;
        this.isHome = false;
        this.alertService.clearAlert();
    }

    deleteDriverEnroll(eachDriverEnroll: DriverEnroll, editDriverEnrollIndex) {
        if (window.confirm('Are sure you want to delete this ?')) {
            this.spinner.show();
            let user = JSON.parse(this.authService.getLocalStorageItem("loginUser"));
            let driverRequest: DriverRequest = new DriverRequest();
            driverRequest.accountCode = user.accountCode;
            driverRequest.drivers = [];
            driverRequest.drivers.push(eachDriverEnroll);
            this.driverEnrollService.deleteDriverEnrolls(driverRequest)
                .subscribe(data => {
                    if (data) {
                        this.spinner.hide();
                        this.alertService.success('Driver Record Deleted Successfully');
                        this.showAll();
                    }
                },
                error => {
                    this.spinner.hide();
                    this.alertService.error(error);
                });
        }
    }

    importCSVFile() {
        this.router.navigateByUrl('/upload-csv');
    }
    showModalAddOrEdit(showModalFlag: string) {
        //this.showModalFlag = showModalFlag;
        //this.driverEnroll  = new DriverEnroll();
        this.router.navigateByUrl('/add-driver');
    }
    showModalEdit(showModalFlag: string) {
        this.showModalFlag = showModalFlag;
        this.driverEnroll = new DriverEnroll();
        //this.router.navigateByUrl('/add-driver');
    }
    submitDriverEnrolls() {

        this.spinner.show();
        var bookFilteredList = this.allDriverEnrolls
            .filter((driver: DriverEnroll) => driver.driverSubmitted == 'N' && driver.driverStatus == '1');

        let user = JSON.parse(this.authService.getLocalStorageItem("loginUser"));
        let driverRequest: DriverRequest = new DriverRequest();
        driverRequest.accountCode = user.accountCode;
        driverRequest.drivers = bookFilteredList;
        this.driverEnrollService.updateDriversEnrolls(driverRequest)
            .subscribe(data => {
                if (data) {
                    this.spinner.hide();
                    this.alertService.success('Submit Drivers Enroll Successfully');
                    this.showAll();
                    this.showthree = true;
                }
            },
            error => {
                this.spinner.hide();
                this.alertService.error(error);
            });
    }
    displayHome(ishome) {
        this.showAll();
        this.isHome = ishome;
    }
}
