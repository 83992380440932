import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { DatePipe } from '@angular/common';
import { SearchFilterPipe } from './pipes/search-filter/search-filter.pipe';
import { SortByFilterPipe } from './pipes/sort-by-filter/sort-by-filter.pipe';

import { ErrorInterceptor } from './interceptors/error.service';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { VerifyComponent } from './components/verify/verify.component';
import { HomeComponent } from './components/home/home.component';
import { CustomerComponent } from './components/uploadcustomer/customer.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { LandingComponent } from './components/landing/landing.component';
import { AlertComponent } from './components/alert/alert.component';
import { ForbiddenValidatorDirective } from './directive/forbiddenname.directive';
import { UppercaseDirective } from './directive/uppercase.directive';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { AppInsightsService } from './services/app-insights/app-insights.service';



import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
} from '@angular/material';
import { AddendumComponent } from './components/addendum/addendum.component';
import { DriverHomeComponent } from './components/driver-home/driver-home.component';
import { AddDriverComponent } from './components/add-driver/add-driver.component';
import {BasicStepper} from './components/stepper/stepper.component';
import { UploadCsvComponent } from './components/upload-csv/upload-csv.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    FooterComponent,
    ChangePasswordComponent,
    VerifyComponent,
    HomeComponent,
    SearchFilterPipe,
    SortByFilterPipe,
    PaginationComponent,
    LandingComponent,
      AlertComponent,
      CustomerComponent,
      AddendumComponent,
      DriverHomeComponent,
      AddDriverComponent,
      BasicStepper,
      UploadCsvComponent,
      ForbiddenValidatorDirective,
      UppercaseDirective,
      ForgotPasswordComponent
      
  ],
  imports: [
      
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
      NgxSpinnerModule,
      MatCardModule, MatToolbarModule, MatButtonModule, MatMenuModule,
      MatNativeDateModule,
      CdkTableModule,
      CdkTreeModule,
      MatAutocompleteModule,
      MatBadgeModule,
      MatBottomSheetModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatStepperModule,
      MatDatepickerModule,
      MatDialogModule,
      MatDividerModule,
      MatExpansionModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatListModule,
      MatMenuModule,
      MatNativeDateModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatRadioModule,
      MatRippleModule,
      MatSelectModule,
      MatSidenavModule,
      MatSliderModule,
      MatSlideToggleModule,
      MatSnackBarModule,
      MatSortModule,
      MatTableModule,
      MatTabsModule,
      MatToolbarModule,
      MatTooltipModule,
      MatTreeModule,
      PdfViewerModule,
      BrowserAnimationsModule, NgxSmartModalModule.forRoot()
  ],
 
  providers: [
      DatePipe,
      AppInsightsService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
