
import { AlertService } from './../../services/alert/alert.service';
import { DriverEnrollService } from './../../services/driver-enroll/driver-enroll.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverEnroll } from './../../models/driver-enroll';
import { DriverRequest } from './../../models/driverrequest';
import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import {Location} from '@angular/common';
import { StatusUpdate } from './../../models/statusrequest';
import { AppInsightsService } from '../../services/app-insights/app-insights.service';

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.css']
})
export class AddDriverComponent implements OnInit {
    @Input() driverEnroll: DriverEnroll;
    @Output() valueChange = new EventEmitter();
    driverTypeShow: boolean;
    editdriver: boolean = false;
    invalidDate: boolean = false;
    invalidName: boolean = false;
    isHome: boolean = false;

    constructor(private driverEnrollService: DriverEnrollService, private router: Router,
      private alertService: AlertService, private appInsightsService: AppInsightsService,
      private spinner: NgxSpinnerService, private authService: AuthenticationService, private _location: Location) {
      appInsightsService.logPageView('add-driver');
    }
    statusUpdate: StatusUpdate = new StatusUpdate();

    ngOnInit() {
        if (this.driverEnroll == null) {
            this.driverEnroll = new DriverEnroll();
        }
        else {
            this.editdriver = true;
            this.driverEnroll.typeValue = '';
            //this.valueChanged();
        }
    }
  
    valueChanged() {
        this.isHome = true;
        this.valueChange.emit(this.isHome);
    }
 

    dateChange(event) {
        if (this.driverEnroll.dateOfBirth) {
            var timeDiff = Math.abs(Date.now() - (Date.parse(event)));
            var age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
            if (age < 16 || age > 72) {
                this.invalidDate = true;
            }
            else {
                this.invalidDate = false;
            }
        }
    }

  NameChange() {
      var firstnamelength = this.driverEnroll.firstName ? this.driverEnroll.firstName.length : 0
      var lastnamelength = this.driverEnroll.lastName ? this.driverEnroll.lastName.length:0;
      var maxlen = firstnamelength + lastnamelength;
      if (maxlen > 24) {
          this.invalidName = true;
      }
      else {
          this.invalidName = false;
      }
  }

  addDriver() {
      this.spinner.show();
      let user = JSON.parse(this.authService.getLocalStorageItem("loginUser"));
      let driverRequest: DriverRequest = new DriverRequest();
      driverRequest.accountCode = user.accountCode;
      this.driverEnroll.customerId = user.customerId;
      driverRequest.drivers = [];
      driverRequest.drivers.push(this.driverEnroll);
      if (this.editdriver == true) {
          this.driverEnrollService.updateDriverEnrolls(driverRequest)
              .subscribe(data => {
                  if (data) {
                      this.spinner.hide();
                      this.alertService.success('Record has been updated Successfully');
                      this.valueChanged();
                  }
              },
              error => {
                  this.spinner.hide();
                  this.alertService.error(error);
              });
      }
      else {
          //this.statusUpdate.customerid = user.customerId;
          this.statusUpdate.accountCode = user.accountCode;
          this.statusUpdate.currentstatus = "driver-list";
          this.driverEnrollService.submitDriverEnrolls(driverRequest)
              .subscribe(data => {
                  if (data) {
                      this.spinner.hide();
                      //this.alertService.success('1 Record have been uploaded Successfully');
                      this.authService.updateStatus(this.statusUpdate)
                          .subscribe(
                          user => {
                              this.spinner.hide();
                              this.router.navigateByUrl('/home');
                              this.alertService.success('1 record has been added successfully');    
                          },
                          error => {
                              this.spinner.hide();
                              this.alertService.error(error);
                          });
                  }
              },
              error => {
                  this.spinner.hide();
                  this.alertService.error(error);
              });
      }
  }

  Cancel(){
      
      if (this.editdriver == false)
          this._location.back();
      else {
          this.valueChanged();
      }
      
  }

}
