import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components go here
import { AppComponent } from '../app.component';
import { LoginComponent } from '../components/login/login.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { HomeComponent } from '../components/home/home.component';
import { LandingComponent } from './../components/landing/landing.component';
import { CustomerComponent } from '../components/uploadcustomer/customer.component';
import { VerifyComponent } from '../components/verify/verify.component';
import { BasicStepper } from '../components/stepper/stepper.component';
import { DriverHomeComponent } from '../components/driver-home/driver-home.component';
import { AddDriverComponent } from '../components/add-driver/add-driver.component';
import { AddendumComponent } from '../components/addendum/addendum.component';
import { UploadCsvComponent } from '../components/upload-csv/upload-csv.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';

// Gaurds go here
import { AuthGuard } from '../guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: LandingComponent
    
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
