import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StatusUpdate } from './../../models/statusrequest';
import { first } from 'rxjs/operators';
import { AuthenticationService } from './../../services/authentication/authentication.service';
import { AlertService } from './../../services/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverEnrollService } from './../../services/driver-enroll/driver-enroll.service';



@Component({
    selector: 'app-addendum',
    templateUrl: './addendum.component.html',
    styleUrls: ['./addendum.component.css']
})
export class AddendumComponent implements OnInit {
    src: any;
    acceptTC: boolean = false;
    fullName: string = '';
    statusUpdate: StatusUpdate = new StatusUpdate();
    constructor(private driverEnrollService: DriverEnrollService, private router: Router, private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private spinner: NgxSpinnerService) { }


    ngOnInit() {
        this.spinner.show();
        let user = JSON.parse(this.authenticationService.getLocalStorageItem("loginUser"));
        this.driverEnrollService.createPDFFile(user.accountCode)
            .subscribe(data => {
                let file = new Blob([data], { type: 'application/pdf' });
                this.src = URL.createObjectURL(file);
                this.spinner.hide();

            }, (error) => {
                this.spinner.hide();
                this.alertService.error(error);
            });
    }


    letsCountinue() {
        this.spinner.show();
        let user = JSON.parse(this.authenticationService.getLocalStorageItem("loginUser"));
        this.driverEnrollService.signPDFFile(user.accountCode, this.fullName)
            .subscribe(data => {
                this.updatestate();

            }, (error) => {
                this.spinner.hide();
                this.alertService.error(error);
            });
    }

    updatestate() {

        let user = JSON.parse(this.authenticationService.getLocalStorageItem("loginUser"));
        //this.statusUpdate.customerid = users.customerId;
        this.statusUpdate.accountCode = user.username;
        this.statusUpdate.currentstatus = "addendumsigned";
        this.authenticationService.updateStatus(this.statusUpdate)
            .pipe(first())
            .subscribe(
            response => {
                user.currentState = 'Add-driver';
                this.authenticationService.setLocalStorageItem(user);
                this.spinner.hide();
                this.router.navigateByUrl('/driver-home');
            },
            error => {
                this.spinner.hide();
                this.alertService.error(error);

            });

    }

}
