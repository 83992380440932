import { User } from './../../models/user';
import { environment } from './../../../environments/environment';
import {StatusUpdate  } from './../../models/statusrequest';
import { ChangePassword } from './../../models/changepassword';
import { ForgotPassword } from './../../models/forgotpassword';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    public showLogoutBtn = false;
    public showDocumentDropdown = false;
    public accountCode = "";
    baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) {
        let users = JSON.parse(this.getLocalStorageItem("loginUser"));
        if (users) {
            this.showLogoutBtn = true;
            if (users.currentState == "Driver-list" || users.currentState == "Add-driver") {
                this.showDocumentDropdown = true;
            }
            else {
                this.showDocumentDropdown = false;
            }
            this.accountCode = users.username;
        }
        else {
            this.showLogoutBtn = false;
            this.showDocumentDropdown = false;
        }
    }

    login(user: User) {
        let url = this.baseUrl + '/login';
        return this.http.post<any>(url, user)
            .pipe(map(user => {
                return user;
            }));
    }
    changepassword(changepwd: ChangePassword) {
        let url = this.baseUrl + '/changepassword';
        return this.http.post<any>(url, changepwd)
            .pipe(map(user => {
                return user;
            }));
    }
    forgotpassword(forgotpassword: ForgotPassword) {
        let url = this.baseUrl + '/forgotpassword';
        return this.http.post<any>(url, forgotpassword)
            .pipe(map(user => {
                return user;
            }));
    }

    updateStatus(statusrequest: StatusUpdate) {
        let url = this.baseUrl + '/updatecustomerstate';
        return this.http.post<any>(url, statusrequest)
            .pipe(map(user => {
                return user;
            }));
    }

    public getLocalStorageItem(key: string): string {
        return localStorage.getItem(key);
    }

    
    

    public setLocalStorageItem(user: any) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('loginUser', JSON.stringify(user));
        this.showLogoutBtn = true;
        this.accountCode = user.username;
        if (user.currentState == "Driver-list" || user.currentState == "Add-driver") {
            this.showDocumentDropdown = true;
        }
        else {
            this.showDocumentDropdown = false;
        }
        //let users1 = JSON.parse(this.getLocalStorageItem("loginUser"));
        //console.log("user value"+users1.username);
    }

   public logout() {
        localStorage.removeItem('loginUser');
        this.showLogoutBtn = false;
        this.showDocumentDropdown = false;
    }
}
