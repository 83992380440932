import { PagerService } from './../../services/pagination/pager.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  constructor(private pagerService: PagerService) { }

  public transform(items: any, keys: string, searchText: any) {

    // RegExp:
    // g modifier: global. All matches (don't return on first match)
    // i modifier: insensitive. Case insensitive match (ignores case of [a-zA-Z])

    // if searchText is undefine then it return the current page data of pagination
    // but if user search then we should not be showing search result from current page 
    // of pagination but instead of whole array of allItems, 
    // so getting this.pagerService.allItems from PagerService

    if (!searchText) return items;
    return (this.pagerService.allItems || [])
      .filter((item) => keys.split(',')
        .some(key => item.hasOwnProperty(key) && new RegExp(searchText, 'gi')
          .test(item[key])));
  }

}
